<template>
  <LoadingSpinner :showLoading="!showLoading" text="Loading Security Info..." textPosition="margin-top:280px;margin-left:810px;" minHeight="500px;" />
</template>



<script>
import ResearchService from '../service/ResearchService';
export default {
  data () {
    return {
      showLoading: true,
    }
  },
  mounted() {
    console.debug( "in equity lookup");
    ResearchService.setSelectedSymbolFromQM(this.$route.params.selectedSymbol, (resp) => {
        if (resp == 'success') {
            console.debug("got equity forwarding on to /equity");
            this.$router.push({ path: '/equity' });
        }
			});
  }

  
}
</script>

<style>

</style>

